<template>
  <div class="ml-16 mt-3 mb-3">

    <!-- Visible only after the user has drawn a polygon -->
    <v-btn 
		v-if="polygonCoords && polygonCoords !== '' && hasRiverInPolygon"
		large
		color="#19A7CE" 
		class="white--text p-5 font-weight-black text-capitalize font-rubik modal-button rounded-md"
		@click="openModal"
	>
		{{ riverDisplayName }}
	</v-btn>

  <v-dialog 
		v-model="modalOpen2" 
		id="dialog2"
		persistent
		max-width="1400px"
		width="auto"
	>
    <v-card >
      <v-card-title class="headline">
        <v-spacer>Leave Classification</v-spacer>
      </v-card-title>
      
      <v-card-text>
        Are you sure you want to leave? You have unsaved metadata.
      </v-card-text>
      
      <v-card-actions>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between cols-12">
              <v-btn
                class="text-capitalize font-weight-bold font-rubik"
                @click="modalOpen2 = false; modalOpen = true"
              >
                Cancel
              </v-btn>

              <v-btn
                color="#19A7CE" 
                class="white--text text-capitalize font-weight-bold font-rubik"
                @click="leaveClassification()"
              >
                Yes, I want to leave
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>

	<v-dialog 
		v-model="modalOpen" 
		id="dialog"
		persistent
		max-width="1400px"
		width="auto"
		overlay-color="#003442"
		overlay-opacity="0.6"
		:class="currentStepId === 3 ? 'set-metadata-card-size' : 'init-new-roi-card-size'"
	>
		<v-card :class="currentStepId === 3 ? 'set-metadata-card-size' : 'init-new-roi-card-size'">
			<v-card-title class="headline">
				<span>
					<span class="step-circle">{{ currentStepId }}</span>          
					{{ stepperHeaderTitle }} - {{ riverName }}
				</span>

				<v-spacer></v-spacer>

				<v-btn icon class="absolute top right-0" @click="closeModal">
					<v-icon class="highlight-icon">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<!-----------------------------------------------------------
				| Step 1. Select desired date range for images
				------------------------------------------------------------>
				<template v-if="this.currentStepId === 1">
					<date-range-picker
            ref="picker"
            opens="right"
            :locale-data="{
              direction: 'ltr',
              format: 'mm/dd/yyyy',
              separator: ' - ',
              applyLabel: 'Apply',
              cancelLabel: 'Cancel',
              weekLabel: 'W',
              customRangeLabel: 'Custom Range',
              daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              firstDay: 0
            }"
            :minDate="null"
            :maxDate="maxDateLimit"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="true"
            v-model="imageRange"
            :linkedCalendars="true"
            @update="onDateSelected"
            class="ma-5"
					>
            <template v-slot:input="picker" style="min-width: 350px;">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
					</date-range-picker>

          <v-alert
            v-if="selectDateErrorMessage.length > 0"
            dismissible
            type="error"
            color="error"
          > 
            {{ selectDateErrorMessage }}

          </v-alert>

          <v-alert :text="true" type="info" variant="tonal">
            Select a maximum range up to <strong>250</strong> dates to classify.
          </v-alert>
				</template>

				<!-----------------------------------------------------------
				| Step 2. Name the river and the polygon
				------------------------------------------------------------>
				<template v-if="this.currentStepId === 2">
          <v-text-field 
            v-model="riverName"
            color="#19A7CE"
            label="Name the river *"
            :placeholder="getRiverName"
            :error-messages="riverNameError"
            @change="updateStore($event)"
            @input="$v.riverName.$touch()"
            @blur="$v.riverName.$touch()"
          ></v-text-field>
          
          <v-text-field
            v-model="polygonName"
            color="#19A7CE"
            label="Name the polygon *"    
            :error-messages="polygonNameError"
            @input="$v.polygonName.$touch()"
            @blur="$v.polygonName.$touch()"
          ></v-text-field>

          <v-textarea
            v-model="polygonNotes"                
            :error-messages="polygonNotesError"  
            color="#19A7CE"
            label="Polygon Notes"
            clearable
            rows="3"
            row-height="15"
            counter
          ></v-textarea>

          <v-alert
            v-if="validateDateRangeErrorMessage.length > 0"
            :prominent="true"
            shaped
            dismissible
            elevation="2"
            type="error"
            color="error"
            class="error-alert"
            transition="slide-x-reverse-transition"
          > 
            {{ validateDateRangeErrorMessage }}

          </v-alert>
        </template>
        
				<!-----------------------------------------------------------
				| Step 3. Card with satellite image, river info and metadata form
				------------------------------------------------------------>
				<template v-if="this.currentStepId === 3">
					<SetMetadataModal
						v-if="showSetMetadataModal"
						:key="modalKey"
						:polygon-id="polygonId"
						:polygon-name="polygonName"
						:polygon-notes="polygonNotes"
						:image-range="imageRange"
						:current-step="this.currentStepId"
						:metrics="metrics"
						:modal-status="modalOpen"
						:user-name="getCurrentUser"
						@date-of-image="dateOfImage=$event"
						@metadata-saved="metadataSaved=$event"
						@go-to-map="modalOpen = false"
						@try-again="deletePolygonReset"
					/>
				</template>

				<!-----------------------------------------------------------
				| Step 4. Display message, when all metadata is saved
				------------------------------------------------------------>
				<template v-if="this.currentStepId === 4"> 
					<v-container class="">
					<v-row class="justify-center">
						<v-col v-if="isFinalStep && this.currentStepId > 2" 
						cols="6"
						class="d-flex justify-center">
						<p class="text-center">
							You have successfully saved metadata for all the available images of the selected date range for that polygon. 
						</p>
						
						</v-col>

					</v-row>
					<v-row class="justify-center">
								<v-btn
									color="primary"
									variant="flat"
									@click="resetAll"
								>
									Finish classification
								</v-btn>
							</v-row>
					</v-container>
				</template>

				<!-- Error Alert Messages -->
				<error-alert    
					class="error-alert mb-4"
					:error="error"
					:error-message="errorMessage"
					@error-closed="error=false"
				></error-alert> 
			</v-card-text>

			<v-card-actions>
        <v-row>
          <v-col>
         
				<div class="d-flex justify-space-between cols-12">
          <v-btn
            v-if="this.currentStepId > 1"
						class="text-capitalize font-weight-bold font-rubik"
						@click="handlePrevious()"
					>
            <i class="mdi mdi-arrow-left"></i>
						Previous
					</v-btn>

					<v-btn
						v-if="this.currentStepId > 1 && this.currentStepId < 3 || !isFinalStep && metadataSaved"
						color="#19A7CE" 
						class="white--text text-capitalize font-weight-bold font-rubik"
						:loading="loading"
						:disabled="invalidForm"
						@click="handleContinue()"
					>
						Continue
            <i class = "mdi mdi-arrow-right"></i>
					</v-btn>
				</div>
           
      </v-col>
        </v-row>

			</v-card-actions>
		</v-card>
	</v-dialog>

</div>
</template>

<script>
  import { withAsync } from             "@/helpers/withAsync";
  import { apiStatus } from             "@/api/constants/apiStatus";
  import { apiStatusComputed } from     "@/api/helpers/computedApiStatus";
  import { setDateRange, deletePolygon } from          "@/api/polygonApi.js";
  import { mapGetters, mapActions } from            'vuex';
  import ErrorAlert from                "@/components/ErrorAlert.vue";
  import SetMetadataModal from          "./SetMetadataModal.vue";
  import { required, maxLength } from              "vuelidate/lib/validators";
  import DateRangePicker from           'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import axios from 'axios';
  import CONFIG from "@/common/config";

    
  export default {

    name: "SetMetadataCarousel",

    props:{
      polygonCoords:{
        type: [Array, Object, String]
      },
      polygonId:{
        type: [Number, String]
      },
      hasRiverInPolygon: {
        type: Boolean,
        default: false
      },
      riverDisplayName: {
        type: String,
        default: ""
      }
    },

    components:{
      ErrorAlert,
      SetMetadataModal,
      DateRangePicker
    },

    data () {
      return{
        currentStepId: 1,
        validateDateRangeErrorMessage: "",
        selectDateErrorMessage: "",
        maxDate: new Date().toISOString().substr(0, 10),
        requiredMessage: "This field is required",
        notANumberMessage: "Set a valid number",
        notInRangeMessage: (min, max) => `Set a range between ${min} and ${max}`,
        modalKey: 0,
        modalOpen: false,
        modalOpen2: false,
        cancelProcedure: false,
        continueClicked: false,
        error: false,
        loading: false,
        errorMessage: "",
        imageRange: {
          startDate: new Date(),    // or another default start date
          endDate: new Date(),      // or another default end date
        },
        coordinates: null,
        polygonName: '',
        polygonNotes: '',

        metrics: null,
        riverName: '',
        metadataSaved: false,
        setDateRange: apiStatus.Idle,
        dateRangeWarning: false,
        isFinalStep: false,
      }
    },

    validations: {
      imageRange: { required },
      riverName: { required },
      polygonName: { required },
      polygonNotes: { maxLength: maxLength(255) }
    },

    computed:{
      ...mapGetters([
        'getCurrentUser',
        'getRiverName',
        'getRiverId',
        'getRiverCustomName',
        'getShowCustomName',
        'getHasRiverInPolygon',
        'areAllDatesSaved',
        'getAvailableDates',
        'getPolygonId'
      ]),

      ...apiStatusComputed([
        "setDateRange"
      ]),

      maxDateLimit() {
        const today = new Date();
        today.setDate(today.getDate() - 1); // Subtract one day to get yesterday

        return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      },

      stepperHeaderTitle() {
        if (this.currentStepId === 1 ) {
          return "Select Date Range";
        } else if(this.currentStepId === 2) {
          return "Set the basic info";
        } else if (this.isFinalStep) {
          return "Finish";
        } else {
          return "Classify the image";
        }
      },

      invalidForm() {
        if( this.currentStepId === 1 ) {          // Date Selection Step.
          return this.imageRange?.length !== 2;
        } else if( this.currentStepId === 2 ) {   // Basic Info Step.
          return !(this.imageRange && this.polygonName && this.$v.polygonNotes.maxLength);
        } else {
          return false;
        }
      },

      imageRangeError(){
        const errors = [];
        if (!this.$v.imageRange.$dirty)return errors;
        !this.$v.imageRange.required && errors.push(this.requiredMessage);
        return errors;
      },

      riverNameError(){
        const errors = [];
        if (!this.$v.riverName.$dirty)return errors;
        // !this.$v.riverName.required && errors.push(this.requiredMessage);
        return errors;
      },

      polygonNotesError(){
        const errors = [];
        !this.$v.polygonNotes.maxLength && errors.push('Notes must be 255 characters or less');
        return errors;
      },

      polygonNameError(){
        const errors = [];
        if (!this.$v.polygonName.$dirty)return errors;
        !this.$v.polygonName.required && errors.push(this.requiredMessage);
        return errors;
      },

      showSetMetadataModal() {
        return !this.isFinalStep && this.currentStepId > 2 && !!this.getAvailableDates && !!this.getAvailableDates.length
      }
    }, 

    methods:{
      ...mapActions([
        'setAvailableDates',
        'setRiverShapeCoordinates',
        'setRiverData'
      ]),

      ...mapGetters([
        "getShapeCoords"
      ]), 

      calculateDaysBetween(startDate, endDate) {
          const start = new Date(startDate);
          const end = new Date(endDate);
          const timeDifference = end - start;
          const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

          return daysDifference;
      },

      onDateSelected(start, end) {
        //ssconsole.log("Selected date range: ", start, end);
        //////console.log( new Date( start.startDate ).getTime() === new Date(start.endDate ).getTime() );
        
        if( new Date( start.startDate ).getTime() === new Date(start.endDate ).getTime() ) {
          this.selectDateErrorMessage = "Please select a date range with at least 2 days.";
          return;
        }

        this.currentStepId++;
        this.metadataSaved = false;
        this.riverName = this.getRiverCustomName || this.getRiverName;
        this.imageRange.startDate = start.startDate;
        this.imageRange.endDate = start.endDate;
        
        this.queryOSMRiverName();
      },


      // Api call, to external api, to retrieve the name of the river contained in the drawn shape
      async queryOSMRiverName() {        
        const bFormatArray = this.getShapeCoords();

        const query = `
        [out:json];
        way["waterway"="river"]["name"](poly:"${bFormatArray}");
        out tags;
        `;

        try {
          const response = await axios.post(CONFIG.river_names_api, query);
          const data = response.data;

          if( "elements" in data ) {
           
              this.$store.commit('setHasRiverInPolygon', true);
              for (const element of data.elements) {
                let riverId = null;
                if ("tags" in element && "name" in element.tags) {
                  this.riverName = element.tags.name;
                  if( "name:en" in element.tags) {
                    riverId = element.id;
                    this.riverName = element.tags["name:en"];              
                  }else{
                    this.riverName = element.tags.name;
                  };

                  let rData = {
                    river_id: riverId,
                    river_name: this.riverName  
                  };
                  
                  this.setRiverData( rData );
                  return this.riverName;
                }
            
              }
          }
        } catch (error) {
          console.error(error);
        }
        return null;
      },

      handlePrevious() {
        if( this.currentStepId > 1 ) {
          this.currentStepId--;
          this.metadataSaved = false;
          this.validateDateRangeErrorMessage = "";
          this.selectDateErrorMessage = "";
        }
      },

      handleContinue() {
        this.isFinalStep = this.areAllDatesSaved && this.currentStepId > 2;
        this.selectDateErrorMessage = "";

        if( this.currentStepId !== 2 ) {
          if( this.currentStepId !== 2 ) this.currentStepId++;
          if(this.currentStepId > 2) this.continueClicked = true;

          this.riverName = this.getRiverCustomName || this.getRiverName;
          this.metadataSaved = false;
        }else {
          let totalDays = this.calculateDaysBetween(this.imageRange.startDate, this.imageRange.endDate);

          if( totalDays >= 250 ) {
            this.validateDateRangeErrorMessage = "The date range is too long. Please select a range of up to 250 days.";
            return;
          }

          this.setImageDateRange();
        }
      },

      openModal() {
        this.modalOpen = true;
        this.cancelProcedure = false;
        this.$emit("procedure-status", this.cancelProcedure);
        this.$emit("modal-status", this.modalOpen);
      },
      //todo the blue drawing polygon isnt removed
      deletePolygonReset () {
          // Make an HTTP call to delete the polygon.
          // await withAsync( deletePolygon, this.getPolygonId );
          // On set date range & set river name (step1/step2)
           this.resetAll();
          localStorage.removeItem('shapeCoordinates');
          this.setAvailableDates([]);          
          this.$emit("shape-coords", "");          
      },

      async closeModal() {
        
        if(this.currentStepId >= 3) {
          this.modalOpen2 = true;

          // const userConfirmed = confirm("Are you sure you want to leave? You have unsaved metadata.")

          // if(!userConfirmed){
          //     return;
          // }

          return;
          // Make an HTTP call to delete the polygon.
          //await withAsync( deletePolygon, this.getPolygonId );
        } 

        // On set date range & set river name (step1/step2)
        this.resetAll();
        this.$emit("modal-status", this.modalOpen);
      },

      leaveClassification(){
        this.resetAll();
        this.$emit("modal-status", this.modalOpen);
      },

      resetAll(){
        this.modalOpen = false;
        this.metadataSaved = false;
        this.cancelProcedure = true;
        this.loading = false;
        // this.riverName = null;
        this.polygonName = null;
        this.polygonNotes = '';
        this.imageRange = {
          startDate: new Date(),
          endDate: new Date(),
        };
        this.currentStepId = 1;
        this.$v.riverName.$reset();
        this.$v.polygonName.$reset();
        this.$v.imageRange.$reset();
        this.$v.polygonNotes.$reset();        
        this.modalKey++;
        this.$emit("procedure-status", this.cancelProcedure);
        this.$store.commit("resetRiverData");
        this.dateRangeWarning = false;
        this.$emit("on-close-modal");
      },

      // Change coordinates form from lat-lng to lng-lat
      transformCoordinates(){
        let shapeCoordinates = this.polygonCoords;

        const transformed = 
          shapeCoordinates[0].map(obj => ({
          lon: obj.lng,
          lat: obj.lat
        }))

        // Add the first point of the polygon again to complete a closed shape.
        transformed.push({ 
            lon: shapeCoordinates[0][0].lng,
            lat: shapeCoordinates[0][0].lat
        })
        return transformed;
      },

      isPolygonNameValid( polygonName ){
        if( !polygonName || polygonName === "" ){
          //this.error = true;
          //this.errorMessage = "Please provide a name for the polygon";
          this.validateDateRangeErrorMessage = "Please provide a name for the polygon";
          return false;
        }

        return true;
      },

      timeoutPromise(ms) {
        return new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Timeout exceeded')), ms)
        );
      },

      async withTimeout(promise, ms) {
        return Promise.race([
            promise,
            this.timeoutPromise(ms)
        ]);
      },

      // Send the desired date range with all the info needed, to get all the available dates-images within the date range
      async setImageDateRange() {
        if( !this.isPolygonNameValid( this.polygonName) ) return;

        this.loading = true;

        /**
         * Get river metadata for the selected date range
         */
        this.setDateRange = apiStatus.Pending;
        const payload = {
          start_date: this.imageRange.startDate,
          end_date: this.imageRange.endDate,
          river_name:  this.getRiverCustomName || this.getRiverName,
          polygon_name: this.polygonName,         
          coordinates: this.transformCoordinates()
        };

        if( this.polygonNotes ) payload.notes = this.polygonNotes;


        try {
            const { response, error } = await this.withTimeout(withAsync(setDateRange, payload), 30000); // 30 seconds
            if (error) {
                //console.error('Error:', error);
            } else {
                //console.log('Response:', response);
            }

            this.loading = false;

            if( error ) {
              let errorMessage = error.response.data.message;

              // Set the status of the API call to error.
              this.setDateRange = apiStatus.Error;

              // Show the error message in the alert box.
              this.validateDateRangeErrorMessage = errorMessage;

              //console.error('Error:', errorMessage);
              //console.error('Condition: ', errorMessage === "No available images on the selected date range. Please select again");
              
              // If the error message is about no available images, go back to the first step.
              //if( errorMessage === "No available images on the selected date range. Please select again") this.currentStepId = 1;

              return false;
            }

            // Set the status of the API call to success.
            this.setDateRange = apiStatus.Success;

            let apiResponse = response.data.data;        

            const uniqueDates = Array.from(new Set( response.data.data.available_dates ));

            this.$store.dispatch("setAvailableDates", uniqueDates);
            this.$store.dispatch("setPolygonId", apiResponse.polygon_id);

            let rData = {
              river_id: apiResponse.river.id,
              river_name: apiResponse.river.name  
            };

            this.$store.dispatch("setRiverData", rData);

            if( !response.data.data.metrics ) {
              this.metrics = {
                mean: "No data",
                median: "No data"
              }
            }else {
              this.metrics = {
                mean : response.data.data.mean_NDWI,
                median: response.data.data.median_NDWI
              };
            }

            this.currentStepId++;
        } catch (timeoutError) {
            console.error('Operation timed out:', timeoutError);
        }
      },

      hadnleDateInput(){
        this.$v.imageRange.$touch();
        this.checkDateRange();
      },

      checkDateRange() {
        if (this.imageRange && this.imageRange.length === 2) {
          const startDate = new Date(this.imageRange[0]);
          const endDate = new Date(this.imageRange[1]);
          
          const differenceInMonths = Math.abs(
            (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth()
          );

          if (differenceInMonths >= 6) {
            this.dateRangeWarning = true;
          }
        }
      },

      updateStore(newValue) {
        this.$store.commit('setRiverCustomName', newValue);
      },

    },

    watch: {
      modalOpen(newValue) {
      if (newValue) {
        this.imageRange = {
          startDate: new Date(),
          endDate: new Date(),
        };
      }
    },
      polygonCoords(newVal){
        if(this.coordinates !== this.polygonCoords){
          this.currentStepId = 1;
          this.imageRange = {
          startDate: new Date(),
          endDate: new Date(),
        };
          this.riverName = null;
          this.polygonName = null;
          this.polygonNotes = '';
        }
      },

      imageRange(newVal){
        this.$emit("image-range", newVal);
      },

      riverName( newVal ) {
        this.$emit("river-name", newVal);
      },

      polygonName(newVal) {
        this.$emit("polygon-name", newVal);
      },

      cancelProcedure(newVal) {
        if(this.$store.state.Polygons.dates.length == 0 && newVal == true){
          this.currentStepId = 1;
          this.imageRange = {
          startDate: new Date(),
          endDate: new Date(),
        };
          this.isFinalStep = false;
        }
      },
    },

    filters: {
      date(value) {
        return value.toLocaleDateString();
      },
    },
  }
</script>

<style scoped>
  .modal-button {
    z-index: 9999;
  }

  .font-rubik{
    font-family: 'Rubik', sans-serif;
  }
  .text-color{
    color: #19A7CE;
  }

  .stepper-header {
    box-shadow: none; 
    border-bottom: 2px solid rgb(228, 228, 228); 
    background-color: rgb(255, 255, 255);
  }
  .v-divider {
    border-color: transparent !important;
  }

  .step-circle {
    background-color: #19A7CE;
    color: white;
    padding: 5px 10px;
    border-radius: 100%;
    text-align: center
  }

  .highlight-icon {
    color: #19A7CE;
    font-size: 24px !important;
  }

  .set-metadata-card-size {
    min-height: 600px;
    height: 100%;
  }

  .init-new-roi-card-size {
    min-height: 400px;
    height: 100%;
    width:800px;
  }
</style>