<script>
/************************************************************************************************************
 * 
 * Documentation: https://innologica.github.io/vue2-daterange-picker/#installation
 * 
 ***********************************************************************************************************/
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: "BaseDateRangePicker",
    components: {
        DateRangePicker
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({ start: null, end: null })
        },
        disabledDates: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    created() {
        this.dateRange = this.value;
    },
    data() {
        return {
            dateRange: this.value,
            minDate: new Date(new Date().getFullYear(), 0, 1),
            maxDate: new Date().toISOString().split('T')[0],
            localeData: {
                format: 'DD-MM-YYYY',
                separator: ' - ',
                applyLabel: 'Apply',
                opens: 'right',
                cancelLabel: 'Cancel',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                firstDay: 1
            },
            customRanges: {
                'This Month': [
                    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                ],
                'Last Year': [
                    new Date(new Date().getFullYear() - 1, 0, 1),
                    new Date(new Date().getFullYear() - 1, 11, 31)
                ],
                'Last Month': [
                    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                    new Date(new Date().getFullYear(), new Date().getMonth(), 0)
                ]
            }
        }``
    },
    watch: {
        value(newValue) {
            this.dateRange = newValue;
        }
    },
    computed: {
        maxDateLimit() {
            const today = new Date();
            today.setDate(today.getDate() - 1); // Subtract one day to get yesterday

            return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        },
    }, 
    methods: {
        updateValues() {
            this.$emit('input', this.dateRange);
        },
        // Method to format the selected date range with null checks
        displayDateRange(startDate, endDate) {
            return `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`;
        },
        // Helper method to format individual dates, with null checks
        formatDate(date) {
            if (!date) return ''; // Return an empty string if date is null or undefined
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        
        isDateDisabled(date) {
            return this.disabledDates.some(disabledDate => {
                // disabledDate is in the format of 'YYYY-MM-DD'

                let day = String(date.getDate()).padStart(2, '0');
                let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                let year = date.getFullYear();

                let formattedDate = `${year}-${month}-${day}`;

                // console.log( "Disabled Date: ", disabledDate );
                // console.log( "Selected Dates: ", formattedDate );
                // console.log( "\n" );

                return (
                    disabledDate === formattedDate
                );
            });
        },

        dateFormat(classes, date) {
            if( this.isDateDisabled(date) ) {
                // console.log( "Date: ", date);
                // console.log( "Disabled: ",  this.isDateDisabled(date) );
                // console.log("\n");

                classes.disabled = true;
            }

            return classes;
        }
    }
}
</script>

<template>
    <div>
        Extend Selected Dates:
        <date-range-picker 
            ref="picker"  
            v-model="dateRange"
            :locale-data="localeData"
            :min-date="minDate" 
            :max-date="maxDateLimit"
            :time-picker="false"
            :time-picker24-hour="false"
            :single-date-picker="false"
            :date-format="dateFormat"
            :show-week-numbers="true"
            :auto-apply="true"
            :ranges="customRanges"
            :disabled-dates="isDateDisabled"
            @update="updateValues"
            class="date-picker-component"
            append-to-body
        >
            <template v-slot:input="picker">
                <input
                    type="text"
                    class="form-control"
                    :value="displayDateRange(picker.startDate, picker.endDate)"
                    readonly
                />
            </template>
        </date-range-picker>
    </div>
</template>

<style scoped>
    .date-picker-component {
        width: 100%;
    }
</style>
