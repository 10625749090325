<template>
    <div class="d-flex align-center hydrotypeChart-container">
        <v-btn
            color="primary"
            class="text-capitalize"
            :loading="getHydrotypeChartStatus_Pending"
            @click.stop="openHydrotypeChart"
            text
        >
            Hydrotype Chart
            <v-icon
                color="primary"
                class="ml-2"
            >
                mdi-chart-line
            </v-icon>

        </v-btn>

        <v-dialog
            v-if="hydrochartData"
            v-model="hydroChartDialog"
            class="pa-4"
            width="auto"
        >
            <v-card class="pa-8">
                <v-row class="justify-center">
                    <v-col cols="12" class="text-center font-weight-bold text-h6">
                        <date-range-picker
                            ref="picker"
                            opens="center"
                            :minDate="null"
                            :maxDate="null"
                            :singleDatePicker="false"
                            :timePicker="false"
                            :timePicker24Hour="false"
                            :showWeekNumbers="true"
                            :showDropdowns="true"
                            :autoApply="true"
                            locale="{
                                format='MM/DD/YYYY'
                            }"
                            v-model="selectedDateRange"
                            @update="onDateSelected"
                            class="ma-5"
                            style="min-width: 200px;"
                        ></date-range-picker>
                        
                        <v-alert
							v-if="errorMessage" 
							closable
							:text="true" 
							type="error" 
							variant="tonal"
                            class="w-100"
						>
							{{ errorMessage }}
						</v-alert>
                    </v-col>
                    <v-col cols="12" class="text-center font-weight-bold text-h6">
                        {{ hydrochartData?.plot_title }}
                    </v-col>
                </v-row>

                <v-img :src="hydrotypeChart" height="500" contain></v-img>
                
                <!-- HydroChart Region -->
                <div v-if="hydrochartData?.region" class="mt-4 pa-2 text-center" style="background: darkred; color: white;">
                    <span class="white--text font-weight-bold">
                        {{ hydrochartData?.region }}
                    </span>
                </div>

                <v-row class="pa-4 justify-center">
                    <v-col cols="3" class="d-flex flex-column text-center">
                        <p class=""><strong>Mf:</strong> <span>{{ hydrochartData.flowing_permanence }}</span></p>
                        <p class=""><strong>Md:</strong> <span>{{ hydrochartData.dry_permanence }}</span></p>
                        <p class=""><strong>Mp:</strong> <span>{{ hydrochartData.ponding_permanence }}</span></p>
                    </v-col>
                    <v-col cols="3" class="d-flex flex-column text-center">
                        <p class=""><strong>Cloudy Images:</strong> <span>{{ hydrochartData.cloudy_images }}</span></p>
                        <p class=""><strong>Revisit Time:</strong> <span>{{ hydrochartData.revisit_time }}</span></p>
                        <p class=""><strong>Effective revisit time:</strong> <span>{{ hydrochartData.effective_revisit_time }}</span></p>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getHydrotypeChart } from "@/api/polygonApi.js";
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import ErrorAlert from "@/components/ErrorAlert.vue";
import CONFIG from "@/common/config";

export default {
    name: "DateFlowChart",

    components: { ErrorAlert, DateRangePicker },

    props: {
        polygonId: {
            type: Number,
        },
        riverId: {
            type: Number,
        },
        startDate: {
            type: String,
        },
        endDate: {
            type: String,
        },
        preselectedDateRange: {
            required: false
        }
    },
    data() {
        return {
            error: false,
            errorMessage: "",
            selectedDateRange: {
                    startDate: this.preselectedDateRange[0],    // taken from the available dates fetched for that polygon
                    endDate: this.preselectedDateRange.slice(-1)[0],      // taken from the available dates fetched for that polygon
                },
            hydroChartDialog: false,
            hydrochartData: null,
            getHydrotypeChartStatus: apiStatus.Idle
        };
    },

    computed: {
        ...apiStatusComputed("getHydrotypeChartStatus"),
        hydrotypeChart() {
            return CONFIG.image_api.concat(this.hydrochartData?.plot_path);
        }
    },

    methods: {
        convertTimestampToDate(timeStamp) {
            // Create a new Date object from the date string
            const dateObj = new Date(timeStamp);

            // Extract the parts
            const day = String(dateObj.getUTCDate()).padStart(2, '0');
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = dateObj.getUTCFullYear();

            return `${year}-${month}-${day}`;
        },

        async onDateSelected( selectedDate ) {
            this.selectedDateRange.startDate = this.convertTimestampToDate(selectedDate.startDate);
            this.selectedDateRange.endDate = this.convertTimestampToDate(selectedDate.endDate);

            const response = await this.getHydrotypeChart();

            if( !response ) {
                this.getHydrotypeChartStatus = apiStatus.Error;
                //this.hydroChartDialog = false;
                return;
            }

            this.getHydrotypeChartStatus = apiStatus.Success;
            this.hydrochartData = response;
        },

        async openHydrotypeChart() {
            this.getHydrotypeChartStatus = apiStatus.Pending;
            const response = await this.getHydrotypeChart();
            
            if( !response ) {
                this.getHydrotypeChartStatus = apiStatus.Error;
                this.hydroChartDialog = true;
                this.hydrochartData = [];
                console.log( "B" )
                return;
            }

            console.log( "A" )
            this.getHydrotypeChartStatus = apiStatus.Success;
            this.hydrochartData = response;
            this.hydroChartDialog = true;
        },

        async getHydrotypeChart() {
            const payload = {
                river_id: this.riverId,
                polygon_id: this.polygonId,
                start_date: this.selectedDateRange.startDate,
                end_date: this.selectedDateRange.endDate,
            };

            const { response, error } = await withAsync(
                getHydrotypeChart,
                payload
            );

            if( error ) {
                this.error = true;
                
                if (error.response.status === 409) {
                    this.errorMessage = error.response.data.message;
                    return;
                }

                if (error.response.data.errors?.polygon_id) {
                    this.errorMessage = "The range of dates you selected is invalid";    
                    return;
                }

                this.errorMessage = error.response.data.message;

                return;
            }

            return response.data;
        },

        updateHydrotypeGraph(plot_path) {
           this.hydrotypeChart = CONFIG.image_api.concat(plot_path);

        },
    },
};
</script>

<style lang="scss" scoped>
.hydrotypeChart-container{
    position: relative;

    .alert {
        position: absolute;
        left:18px;
        top: 56px;
        z-index: 2000;
        
    }
}
</style>