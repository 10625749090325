<template>
    <div>
		<!-- Card to display the satellite image -->
		<MetadataForm
			ref="form"
			:polygonId="getPolygonId"
			:preSelectedDate="getAvailableDates[0]"
			:dateList="getAvailableDates"
			@form-updated="handleFormUpdate($event)"
			@form-submitted="$emit('metadata-saved', true)"
			@on-error="showErrorDialog()"
			@image-generated="imageCreatedAt=$event"
			:other-metadata="other_metadata"
		/>

		<v-dialog 
			v-model="errorDialog"
			id="dialog"
			persistent
			max-width="400px"      
			overlay-color="#003442"
			overlay-opacity="0.6"
			class="dialog"
		>
			<v-card>
				<v-card-title class="headline">          
					Polygon size error

					<v-spacer></v-spacer>

					<v-btn icon class="absolute top right-0" @click="closeErrorDialog">
						<v-icon class="highlight-icon">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text>
					<p class="mb-2"> Polygon you just drew is too big</p>

					<ul class="pb-2">
						<li> Please remove this polygon</li>
						<li> Draw again a smaller one</li>
					</ul>

					<v-container>
						<v-row class="justify-center">
							<v-btn
								color="primary mt-4"
								variant="flat"
								@click="closeErrorDialog"
							>
								Try Again
							</v-btn>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { apiStatus } from               "@/api/constants/apiStatus";
import { apiStatusComputed } from       "@/api/helpers/computedApiStatus";
import { mapGetters } from  "vuex";
import MetadataForm from "@/components/metadataModal/MetadataForm.vue";
import MetadataInfo from "@/components/metadataModal/MetadataInfo.vue";

export default {
  name: "SetMetadataModal",

  components:{
    MetadataForm,
    MetadataInfo
  },

  props:{
    userName:{
      type: String
    },
    polygonId:{
      type: Number,
      required: false
    },
    polygonName:{
      type: String,
      required: false
    },
    polygonNotes:{
      type: String
    },
    imageRange:{
      type: Object
    },
    continueClicked: {
      type: Boolean
    },
    currentStep:{
      type: [Number, String]
    },
    metrics:{
      type: Object
    },

    cancelProcedure:{
      type: Boolean
    },
 
    cloudiness:{
      type: [Number, String]
    },


  },

  data() {
    return {
      errorDialog: false,
      shapeCoordinates: localStorage.getItem("shapeCoordinates"),
      valid: false,
      error: false,
      continue: false,
      dateOfImage: "",
      setMetadataStatus: apiStatus.Idle,
      formData: {},
      imageCreatedAt: null
    };
  },

  computed: {
    ...apiStatusComputed("setMetadataStatus"),
    ...mapGetters([
      "getRiverCustomName",
      "getRiverName",
      "getRiverId",
      "getAvailableDates",
      "getSavedDates",
      "getPolygonId",
    ]),

    other_metadata() {
        return {
            user_name: this.userName,
            river_name: this.riverName,
            created_at: this.imageCreatedAt,
            polygon_name: this.polygonName,
            polygon_notes: this.polygonNotes
        }
    },

    // Get the current date in the dd-mm-yyyy format
    // todo make this a utility function
    // currentDate() {
    //   const date = new Date();
    //   const year = date.getFullYear();
    //   const month = String(date.getMonth() + 1).padStart(2, "0");
    //   const day = String(date.getDate()).padStart(2, "0");
    //   return `${day}-${month}-${year}`;
    // },

    // reverseDates() {
    //     return this.getAvailableDates.slice().sort().reverse();
    // },

    riverName() {
      return this.getRiverName;
    },

    riverId() {
      return this.getRiverId;
    }
  },

  methods: {
    showErrorDialog() {
      this.errorDialog = true;      
    },

    closeErrorDialog() {
      this.errorDialog = false;
      this.$emit('try-again');
      this.resetForm();
    },
    
    handleFormUpdate(formData) {
        this.formData = formData;
        this.dateOfImage = formData.selectedDate;
    },

    // Clear the form to start again
    resetForm() {
      this.$refs.form.reset();
    }
  },

  created() {
    this.continue = false;
  },

  watch:{

    dateOfImage(newVal){
      this.$emit("date-of-image", newVal);
    },

    setMetadataStatus_Success(newVal){
      this.$emit("metadata-saved", newVal);
    },

    // todo probably this is not needed
    continueClicked(newVal){
      this.continue = newVal;
      this.resetForm();
    },

    cancelProcedure(newVal){
      if(newVal === true){
        this.waterLevel = "";
        this.riverName = null;
        this.dateOfImage = "";
        this.$store.commit("resetRiverData");
      }
    },

    currentStep(newVal){
      if(newVal > 3){
        this.resetForm();
      }
    },
  }
};
</script>