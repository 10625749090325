<script>
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";

export default {
    name: "ContinuePreviousWorkDateSelection",
    props: {
        selectedDateRange: {
            type: Object,
            required: false,
            default: null
        },
        disabledDates: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            dateRange: this.selectedDateRange
        }
    },
    components: {
        BaseDateRangePicker
    },
    watch: {
        dateRange( newDateRange ) {
            console.log('newDateRange', newDateRange);
            this.$emit('change-date', newDateRange);
        }
    }
}
</script>

<template>
   <div>
    <BaseDateRangePicker
        v-model="dateRange"
        :disabled-dates="disabledDates"
    />
   </div>
</template>
